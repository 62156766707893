import React from 'react';
import '../styles/globals.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';
import { footer, navigation } from '../mockdata';
import { useRouter } from 'next/router';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  return (
    <>
      <Head>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <title>iTero Guides</title>
      </Head>
      {!router.pathname.includes('/scanner') && <Navigation {...navigation} />}
      <main>
        <Component {...pageProps} />
      </main>
      {!router.pathname.includes('/scanner') && <Footer {...footer} />}
    </>
  );
}

export default MyApp;
