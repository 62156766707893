const navigation = {
  image:
    'https://storagy-advent-core-ppr.s3.amazonaws.com/storage/edc80ea4-f1b0-420a-bc36-1e15252b6e5c.png',
  imageAlt: 'itero logo',
  globalLinks: [
    {
      children: 'ALIGN TECHNOLOGY, INC.',
      href: 'https://www.aligntech.com/',
      target: '_blank',
    },
    {
      children: 'INVISALIGN DOCTOR LOGIN',
      href: 'https://vip.invisalign.com/v3/login.action',
      target: '_blank',
    },
    {
      children: 'MYITERO LOGIN',
      href: 'https://myitero.com/login',
      target: '_blank',
    },
  ],
  mainLinks: [
    {
      children: 'Grow your practice',
      href: 'https://itero.com/grow-your-practice',
      target: '_blank',
    },
    {
      children: 'Our solutions',
      href: 'https://itero.com/our-solutions',
      target: '_blank',
    },
    {
      children: 'Why iTero',
      href: 'https://itero.com/why-itero',
      target: '_blank',
    },
    {
      children: 'Education and support',
      href: 'https://itero.com/education-and-support',
      target: '_blank',
    },
    {
      children: 'Lab locator',
      href: 'https://global.itero.com/en/lab_locator',
      target: '_blank',
    },
  ],
};

const footer = {
  copyright: '© %year% Align Technology, Inc.',
  privacy: {
    href: 'https://itero.com/en/privacy-policy',
    target: '_blank',
    children: 'Privacy',
  },
  termsOfUse: {
    href: 'https://itero.com/en/terms-of-use',
    target: '_blank',
    children: 'Terms of Use',
  },
  logos: [
    {
      src: 'https://storagy-advent-core-ppr.s3.amazonaws.com/storage/4437cb61-a6d1-4176-85e2-bdb2a54f4dec.png',
      alt: 'Align logo',
      url: 'https://www.aligntech.com',
      target: '_blank',
    },
    {
      src: 'https://storagy-advent-core-ppr.s3.amazonaws.com/storage/37721090-5da0-4b2f-999c-359260a77946.png',
      alt: 'Invisalign logo',
      url: 'https://www.invisalign.com/',
      target: '_blank',
    },
    {
      src: 'https://storagy-advent-core-ppr.s3.amazonaws.com/storage/edc80ea4-f1b0-420a-bc36-1e15252b6e5c.png',
      alt: 'iTero logo',
      url: 'https://www.itero.com',
      target: '_blank',
    },
  ],
};

const productList = {
  title: 'Manual Repository',
};

const product = {
  error: 'This manual is not available in this locale',
  error_manuals: 'Manuals are not available in this language',
  user_manual: 'User manual',
  cart_configuration: '(cart configuration)',
  mobile_configuration: '(mobile configuration)',
  notes: `<p class="page-description">Notes:<ul class="page-description"><li>If you would like to order a free printed copy of the user manual for your iTero model in your local language, please contact your local representative/distributor or Align Customer Support.</li><li>If the Operation Manual and Instructions for Use for your iTero model is not available on the website, please request a free printed copy from your local representative/distributor or Align Customer Support. Please note that printed manuals can be obtained only for the iTero models that are available in your region.</li><li>In order to view a PDF digital copy of the Operation Manual or IFU, the user must have Acrobat Reader Version 2019.012.20034 or compatible.</li></ul></p><p class="page-description">[S] Indicates a document that has been revised due to regulatory and/or safety reasons</p>`,
  back_to_list: '← Back to products list',
  back_to_product: '← Back to product page',
  previous_versions: 'Previous Manual versions →',
  previous_manuals: 'Previous Manuals',
  addendums: 'Addendums',
};

const selector = {
  select_language: 'Select Language',
};

const errorCodes = {
  400: 'Bad Request',
  404: 'This page could not be found',
  405: 'Method Not Allowed',
  500: 'Internal Server Error',
  default: 'An unexpected error has occurred',
};

export { footer, navigation, productList, product, selector, errorCodes };
