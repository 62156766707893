import React from 'react';
import cx from 'clsx';
import Link from 'next/link';
import classes from './Anchor.module.scss';

export default function Anchor({
  className,
  href,
  children,
  ...others
}: React.HTMLProps<HTMLAnchorElement>) {
  if (!href) {
    return null;
  }

  return (
    <Link href={href} passHref>
      <a className={cx(classes.anchor, className)} {...others}>
        {children}
      </a>
    </Link>
  );
}
