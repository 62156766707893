import React from 'react';
import cx from 'clsx';
import Image from 'next/future/image';
import Container from '../Container/Container';
import Anchor from '../Anchor/Anchor';
import classes from './Navigation.module.scss';

type Link = {
  children?: string;
  href?: string;
  target?: string;
};

export interface NavigationProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  image?: string;
  imageAlt?: string;
  globalLinks?: Link[];
  mainLinks?: Link[];
}

export default function Navigation({
  className,
  image,
  imageAlt,
  globalLinks,
  mainLinks,
  ...others
}: NavigationProps) {
  return (
    <nav className={cx(classes.navigation, className)} {...others}>
      <Container className={classes.navigationInner}>
        <Anchor className={classes.logo} href="/">
          <Image
            className={classes.logoImage}
            src={image || ''}
            alt={imageAlt}
            width="300"
            height="300"
            sizes="100vw"
            unoptimized
          />
        </Anchor>
        <div className={classes.navigationSection}>
          <div className={classes.navigationGlobals}>
            <div className={classes.navigationGlobalsLinks}>
              {globalLinks?.map((link, index) => (
                <Anchor {...link} key={index} className={classes.navigationGlobalLink} />
              ))}
            </div>
          </div>
          <div className={classes.navigationMain}>
            {mainLinks?.map((link, index) => (
              <Anchor className={classes.navigationMainLink} {...link} key={index} />
            ))}
          </div>
        </div>
      </Container>
    </nav>
  );
}
