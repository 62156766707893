import React from 'react';
import cx from 'clsx';
import Image from 'next/future/image';
import Container from '../Container/Container';
import Anchor from '../Anchor/Anchor';
import Text from '../Text/Text';
import classes from './Footer.module.scss';

type Logo = {
  src: string;
  alt: string;
  url: string;
  target: string;
};

export interface FooterProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  logos?: Logo[];
  copyright?: string;
  privacy?: React.HTMLProps<HTMLAnchorElement>;
  termsOfUse?: React.HTMLProps<HTMLAnchorElement>;
}

export default function Footer({
  className,
  logos,
  copyright,
  privacy,
  termsOfUse,
  ...others
}: FooterProps) {
  return (
    <footer className={cx(classes.footer, className)} {...others}>
      <Container className={classes.container}>
        <div className={classes.footerInner}>
          <div className={classes.footerSection}>
            <div className={classes.footerLogos}>
              {logos?.map((logo, index) => (
                <Anchor
                  key={index}
                  className={classes.footerLogo}
                  href={logo.url}
                  target={logo.target}
                >
                  <Image
                    src={logo.src}
                    alt={logo.alt || ''}
                    sizes="100vw"
                    fill
                    className={classes.logoImage}
                    unoptimized
                  />
                </Anchor>
              ))}
            </div>

            <div className={classes.footerCopyright}>
              {copyright && (
                <Text className={classes.copyrightText}>
                  {copyright?.replace('%year%', new Date().getFullYear().toString())}
                </Text>
              )}
              {privacy && <Anchor {...privacy} />}
              {termsOfUse && <Anchor {...termsOfUse} className={classes.copyrightLink} />}
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
