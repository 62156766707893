import React from 'react';
import cx from 'clsx';
import classes from './Text.module.scss';

export interface TextProps<T> extends React.HTMLProps<T | HTMLParagraphElement> {
  component?: any;
  align?: 'left' | 'center' | 'right';
  transform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
  weight?: number | 'bold' | 'semibold' | 'normal' | 'thin';
}

export default function Text<T>({
  className,
  component: Component = 'p',
  children,
  align = 'left',
  transform = 'none',
  style,
  weight,
  ...others
}: TextProps<T>) {
  return (
    <Component
      className={cx(classes.text, className)}
      style={{
        ...style,
        textAlign: align,
        textTransform: transform || 'none',
        ...(weight ? { fontWeight: weight } : {}),
      }}
      {...others}
    >
      {children}
    </Component>
  );
}
